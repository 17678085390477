import { useParams } from 'react-router';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'react-i18next';
import { nb, enUS, sv } from 'date-fns/locale';
import SaleTermTeams from '../TeamsInfo.json';
import '../SASS/base/_SaleTerms.scss';
import i18n from '../i18next';

interface ISaleTermTeams {
  id: number;
  shortname: string;
  name: string;
  orgName: string;
  orgNumber: string;
  phoneNumber: string;
  email: string;
  address: string;
}

const SaleTerms = () => {
  const { team } = useParams<string>();

  const currentTeamSaleTerms = SaleTermTeams.find(
    (teamTerms: ISaleTermTeams) => teamTerms.shortname === team,
  );

  const { t } = useTranslation();
  const siteLanguage = i18n.use(LanguageDetector).language;

  return (
    <div className="SaleTerms">
      <div className="SaleTerms-container">
        <h1>
          {t('Terms.SaleTermsPageTitle')}
        </h1>
        <h2>{currentTeamSaleTerms?.orgName}</h2>
        <span>
          <h3>{siteLanguage === 'enUS' ? '1. Agreement' : '1. Avtalen'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? 'The agreement consists of these terms and conditions of sale, information provided in the order solution and any specially agreed terms. In the event of any conflict between the information, what has been specifically agreed between the parties takes precedence, provided that it does not contradict mandatory legislation. The agreement will also be completed by relevant legal provisions regulating the purchase of goods between traders and consumers.' : `
            Avtalen består av disse salgsbetingelsene, opplysninger gitt i bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved eventuell motstrid mellom opplysningene, går det som særskilt er avtalt mellom partene foran, så fremt det ikke strider mot ufravikelig lovgivning. Avtalen vil i tillegg bli utfylt av relevante lovbestemmelser som regulerer kjøp av varer mellom næringsdrivende og forbrukere.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '2. Parties' : '2. Partene' }</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? `The seller is ${currentTeamSaleTerms?.orgName} (${currentTeamSaleTerms?.orgNumber}), ${currentTeamSaleTerms?.address}, ${currentTeamSaleTerms?.email}, ${currentTeamSaleTerms?.phoneNumber}, and is termed in the following as seller/seller. The Buyer is the consumer who makes the order and is referred to in the following as the Buyer/Buyer.` : `
            Selger er ${currentTeamSaleTerms?.orgName} (${currentTeamSaleTerms?.orgNumber}), ${currentTeamSaleTerms?.address}, ${currentTeamSaleTerms?.email}, ${currentTeamSaleTerms?.phoneNumber}, og betegnes i det følgendesom selger/selgeren. Kjøper er den forbrukeren som foretar bestillingen, og betegnes i det følgende som kjøper/kjøperen.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '3. Price' : '3. Pris'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? 'The stated price for the item and services is the total price the buyer will pay. This price includes all taxes and additional costs. Additional costs that the seller before the purchase has not informed about, the buyer shall not bear. The Seller may without changing the ticket price without retroactively providing retroactive effect for already sold tickets for the same match or event.' : `
            Den oppgitte prisen for varen og tjenester er den totale prisen kjøper skal betale. Denne prisen inkluderer alle avgifter og tilleggskostnader. Ytterligere kostnader som selger før kjøpet ikke har informert om, skal kjøper ikke bære. Selger kan uten endre billettpris uten at det gis tilbakevirkende kraft for allerede solgte billetter for samme kamp eller arrangement.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '4. Conclusion of agreements' : '4. Avtaleinngåelse'}</h3>
          <br />
          <p>
            {`
            Avtalen er bindende for begge parter når kjøperen har sendt sin bestilling til selgeren. Avtalen er likevel ikke bindende hvis det har forekommet skrive- eller tastefeil i tilbudet fra selgeren i bestillingsløsningen eller i kjøperens bestilling, og den annen part innså eller burde ha innsett at det forelå en slik feil.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '5. Payment' : '5. Betalingen'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? 'The seller may claim payment for the item from the time it is shipped from the seller to the buyer. If the buyer uses a credit or debit card at the time of payment, the seller can reserve the purchase price on the card at the time of booking. The card will be charged on the same day the item is shipped. Buyers under the age of 18 cannot pay with subsequent invoice. Payment is made through VIPPS or Stripe, and is charged when the ticket is sent by SMS or email. All cards supported by VIPPS and Stripe are accepted at all times.' : `
            Selgeren kan kreve betaling for varen fra det tidspunkt den blir sendt fra selgeren til kjøperen. Dersom kjøperen bruker kredittkort eller debetkort ved betaling, kan selgeren reservere kjøpesummen på kortet ved bestilling. Kortet blir belastet samme dag som varen sendes. Kjøpere under 18 år kan ikke betale med etterfølgende faktura. Betaling skjer gjennom VIPPS eller Stripe, og belastes når billett er sendt via SMS eller epost. Det aksepteres til enhver tid alle kort som støttes av VIPPS og Stripe.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '6. Delivery' : '6. Levering'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? 'Delivery is made when the buyer, or his representative, has received the ticket(s). The tickets have been taken over the moment the buyer has received the ticket(s) at the email address registered in connection with the purchase, sent to the given mobile number by SMS, or the buyer has downloaded a pdf file for printing. When purchasing in a club app, tickets will be under "my tickets" after purchases have been made. The tickets are located electronically on your profile and the receipt will be delivered to your e-mail address in PDF format if necessary. Collection of receipt is done if necessary.' : `Levering er skjedd når kjøperen, eller hans representant, har mottatt billetten(e). Billettene er overtatt i det øyeblikket kjøper har mottatt billetten(e) på e-post-adressen som er registrert i forbindelse med kjøpet, sendt til det oppgitte mobilnummeret på SMS, eller kjøper har lastet ned pdf-fil for utskrift. Ved kjøp i klubbapp vil billetter ligge under «mine billetter» etter at kjøp er gjennomført. Billettene ligger elektronisk på din profil og kvittering blir levert til din e-post adresse i PDFformat ved behov. Uthenting av kvittering gjøres ved behov.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '7. Right of withdrawal' : '7. Angrerett'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? " In accordance with Section 22 of the Right of Withdrawal Act, the club does not offer the right of withdrawal when a validity period has been established for when the service can be used and this has begun to run. A ticket purchased has no right of withdrawal if the match is played/executed. Any issues relating to right of withdrawal must be addressed to the club's administration." : `
            I tråd med angrerettloven § 22 tilbyr ikke klubben angrerett ved kjøp hvor det er fastsatt en gyldighetsperiode for når tjenesten kan benyttes og denne har begynt å løpe. En billett som kjøpes har ingen angrerett dersom kampen spilles/gjennomføres. Eventuelle spørsmål tilknyttet angrerett må rettes til klubbens administrasjon.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '8. Use of tickets' : '8. Bruk av billettene'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? `If the buyer is to use a ticket purchased via the club's app or online store, the buyer must have purchased and had a ticket downloaded / issued for the correct match, match day and ticket category before the ticket is used. The tickets are designed for printing in A4 format, display in PDF reader or browser on mobile devices, or for display in the club's app. The ticket is validated either through a scan at the inspector, or self-validation (flashing gif) for presentation to the inspector. Validation method may vary from event to event. Season tickets apply to matches in one or more matches in a given season, and are described separately per. season ticket type. Season tickets can contain all matches or just a selection based on the type of season card. The club has the right to change the match time within the same day without entailing liability or the right to cancel the purchase. If a match or event is moved to another day, arena or canceled, the buyer is entitled to a similar ticket to a similar event. If a match is rescheduled, new tickets will be issued automatically. In the event of cancellations, or moving to another arena or other day, the buyer is entitled to a refund of the ticket (s). Claims for refunds are sent to ${currentTeamSaleTerms?.email} which forwards the claim to the club.` : `
            Dersom kjøper skal benytte billett kjøpt via klubbens app eller nettbutikk, må kjøper ha kjøpt og fått lastet ned/utstedt en billett for riktig kamp, kampdag og billettkategori før billetten brukes. Billettene er utformet for utskrift i A4-format, visning i PDF-leser eller nettleser på mobile enheter, eller for framvisning i klubbens app. Billetten valideres enten gjennom skanning hos kontrollør, eller selv-validering(blinkende gif) for fremvisning til kontrollør. Valideringsmetode kan variere fra arrangement til arrangement. Sesongbilletter gjelder kamper i en eller flere kamper i en gitt sesong, og er beskrevet særskilt pr. sesongbillettype. Sesongbilletter kan inneholde alle kamper eller bare et utvalg basert på type sesongkort. Klubben har rett til å endre kamptidspunkt innenfor samme dag uten at det medfører erstatningsansvar eller rett til heving av kjøpet. Dersom en kamp, eller arrangement flyttes til en annen dag, annen arena eller blir kansellert, har kjøper rett på tilsvarende billett på tilsvarende arrangement. Dersom en kamp omberammes vil nye billetter utstedes automatisk. Ved kanselleringer, eller flytting til annen arena eller annen dag har kjøper rett på refusjon av billetten(e). Krav om refusjon sendes til ${currentTeamSaleTerms?.email} som videreformidler kravet til klubben.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '9. Club responsibilities' : '9. Klubbens ansvar'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? "The online store and app will normally be available and work without errors or omissions. The Club is not liable for any direct or indirect losses incurred by the Buyer or anyone else in connection with use or the club's online store cannot be used as intended." : `
            Nettbutikken og appen vil normalt være tilgjengelig og fungere uten feil eller mangler. Klubben er ikke ansvarlig for direkte eller indirekte tap som kjøper eller noen andre påføres i forbindelse med bruk eller at klubbens nettbutikk ikke kan benyttes som forutsatt.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '10. The customers responsibility' : '10. Kundens ansvar'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? "The Buyer is responsible for purchasing the correct ticket in accordance with the requirements we have for each ticket category, including the correct age category, companion certificate, etc., as well as providing the necessary documentation of the right to purchase in a given category. The Buyer is responsible for providing correct information. The Buyer is responsible for using a valid means of payment. When using a mobile device, the buyer is responsible for ensuring that the device is available, the screen legible, and that the device has sufficient battery for displaying a ticket to the club's controllers at the slip locks." : `
            Kjøper er ansvarlig for å kjøpe korrekt billett i samsvar med de krav vi har for hver billettkategori, herunder riktig alderskategori, ledsagerbevis, etc., samt å medbringe nødvendig dokumentasjon på rett til kjøp i en gitt kategori. Kjøper er ansvarlig for å oppgi korrekte opplysninger. Kjøper er ansvarlig for å benytte et gyldig betalingsmiddel. Ved bruk av mobil enhet er kjøper ansvarlig for at enheten er tilgjengelig, skjermen leselig, og at enheten har tilstrekkelig batteri for fremvisning av billett til klubbens kontrollører ved innslippsslusene.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? "11. Defect of the item - buyer's rights and warranty deadline" : '11. Mangel ved varen - kjøperens rettigheter og reklamasjonsfrist'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? "If there is a defect in the item, the buyer must within a reasonable time after it was discovered or should have been discovered, notify the seller that he or she will invoke the defect. The buyer has always complained in time if it happens within 2 months. from the defect was discovered or should have been discovered. If the item has a defect and this is not due to the buyer or conditions on the buyer's side, the buyer may, in accordance with the rules in the Consumer Purchase Act, Chapter 6, withhold the purchase price, choose between correction and re-delivery, demand price reduction, demand termination of the agreement and / the seller. Complaints to the seller should be made in writing. Correction or re-delivery The buyer can choose between claiming the defect or correcting the delivery of similar items. The seller may nevertheless oppose the buyer's claim if the implementation of the claim is impossible or the seller causes unreasonable costs. Correction or re-delivery must be made within a reasonable time. The seller is in principle not entitled to make more than two remedial attempts for the same defect. Price reduction The buyer can demand a suitable price reduction if the item is not corrected or returned. This means that the ratio between reduced and agreed price corresponds to the ratio between the value of the item in defective and contractual condition. If there are special reasons for it, the price reduction can instead be set equal to the significance of the defect for the buyer. Cancellation If the item has not been repaired or returned, the buyer can also cancel the purchase when the defect is not insignificant." : `
            Hvis det foreligger en mangel ved varen må kjøper innen rimelig tid etter at den ble oppdaget eller burde ha blitt oppdaget, gi selger melding om at han eller hun vil påberope seg mangelen. Kjøper har alltid reklamert tidsnok dersom det skjer innen 2 mnd. fra mangelen ble oppdaget eller burde blitt oppdaget. Dersom varen har en mangel og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan kjøperen i henhold til reglene i forbrukerkjøpsloven kapittel 6 etter omstendighetene holde kjøpesummen tilbake, velge mellom retting og omlevering, kreve prisavslag, kreve avtalen hevet og/eller kreve erstatning fra selgeren. Reklamasjon til selgeren bør skje skriftlig. Retting eller omlevering Kjøperen kan velge mellom å kreve mangelen rettet eller levering av tilsvarende ting. Selger kan likevel motsette seg kjøperens krav dersom gjennomføringen av kravet er umulig eller volder selgeren urimelige kostnader. Retting eller omlevering skal foretas innen rimelig tid. Selger har i utgangspunktet ikke rett til å foreta mer enn to avhjelpsforsøk for samme mangel. Prisavslag Kjøper kan kreve et passende prisavslag dersom varen ikke blir rettet eller omlevert. Dette innebærer at forholdet mellom nedsatt og avtalt pris svarer til forholdet mellom tingens verdi i mangelfull og kontraktsmessig stand. Dersom særlige grunner taler for det, kan prisavslaget i stedet settes lik mangelens betydning for kjøperen. Heving Dersom varen ikke er rettet eller omlevert, kan kjøperen også heve kjøpet når mangelen ikke er uvesentlig.
            `}
          </p>
          <br />
          <br />
          <h3>{siteLanguage === 'enUS' ? '12. Contact us' : '12. Kontakt oss'}</h3>
          <br />
          <p>
            {siteLanguage === 'enUS' ? `In the event of technical issues, is ${currentTeamSaleTerms?.email} available for support, otherwise the Buyer may contact the Club at the contact details provided at the top of these Terms.` : `
            Ved tekniske problemer kan ${currentTeamSaleTerms?.email} benyttes, ellers kan kjøper henvende deg til klubben på kontaktinformasjonen angitt i toppen av disse vilkårene.
            `}
          </p>
        </span>
      </div>
    </div>

  );
};

export default SaleTerms;
